/*-- navigation bar --*/
#main-navigation {
  background-color: lightsteelblue;
}

.email {
  text-align: center;
}


/*-- header --*/

.sky-and-trees {
  background-image: url('images/skyAndTrees.JPG');
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
  border-radius: 0rem !important;
}

/*-- general --*/

.bolded { font-weight: bold; }

.padded-row {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sound {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: 2px;
  border: 0;
}


/*-- bio --*/
#bio img {
  border-radius: 15%;
  float: left;
  height: 200px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 20px;
  pointer-events: none;
  width: 200px;
}

#bio p {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
  text-align: left;
}

#book img {
  width: 18rem;
}

/*-- SermonSeries --*/

.indenter {
  text-indent: -8px;
  padding-left: 26px;
}